import React from "react";
import { Helmet } from "react-helmet";
import logo from "../assets/logo.png";

const Home = () => {
  return (
    <div className="home-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Valscrypt It Services | Innovative IT Services for Your Business
        </title>
        <meta
          name="description"
          content="Valscrypt It Services. offers a comprehensive range of IT services including software development, cloud computing, cybersecurity, and IT consulting. Transform your business with our cutting-edge technology solutions tailored to meet your needs."
        />
        <meta
          name="keywords"
          content="IT services, software development, cloud computing, cybersecurity, IT consulting, technology solutions, business IT support, digital transformation, managed IT services"
        />
      </Helmet>
      <img src={logo} alt="" />
    </div>
  );
};

export default Home;
