import React from "react";
import { Helmet } from "react-helmet";
import one12 from "../assets/tech/one12.jpg";
import oneNord from "../assets/tech/oneNord.jpg";
import one12r from "../assets/tech/one12r.jpg";
import oneCe4 from "../assets/tech/oneCe4.jpg";

const TechStore = () => {
  return (
    <div className="store-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tech Like Wise</title>
        <meta
          name="description"
          content="Explore the latest in tech products, from cutting-edge gadgets to innovative accessories. Shop top brands and find everything from smartphones to smart home devices, all at unbeatable prices. Fast shipping and exceptional customer service await you!"
        />
        <meta
          name="keywords"
          content="tech products, gadgets, electronics, smartphones, smart home devices, computer accessories, latest technology, online tech store, high-quality tech, best tech deals"
        />
      </Helmet>
      <div className="scroll-container">
        <div className="logo">TECH LIKE WISE</div>
        <div className="grid">
          <a href="https://amzn.to/3BAwiNn" target="_blank">
            <div className="card">
              <img src={one12} alt="Toy Gun" />
              <h1>OnePlus 12 (Flowy Emerald, 12GB RAM, 256GB Storage)</h1>
            </div>
          </a>
          <a href="https://amzn.to/3Y6qCDK" target="_blank">
            <div className="card">
              <img src={oneNord} alt="Toy Gun" />
              <h1>Oneplus Nord CE4 (Celadon Marble, 8GB RAM, 128Gb Storage)</h1>
            </div>
          </a>
          <a href="https://amzn.to/4dttRK1" target="_blank">
            <div className="card">
              <img src={one12r} alt="Toy Gun" />
              <h1>OnePlus 12R (Cool Blue, 8GB RAM, 256GB Storage)</h1>
            </div>
          </a>
          <a href="https://amzn.to/3XMjfQr" target="_blank">
            <div className="card">
              <img src={oneCe4} alt="Toy Gun" />
              <h1>
                OnePlus Nord CE4 Lite 5G (Super Silver, 8GB RAM, 128GB Storage)
              </h1>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TechStore;
