import React from "react";
import { Helmet } from "react-helmet";
import syrum from "../assets/syrum.jpg";
import scrub from "../assets/scrub.jpg";
import himalayaSyrum from "../assets/himalaya-syrum.jpg";
const GlamCosmetics = () => {
  return (
    <>
      <div className="store-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Glam Cosmetics</title>
          <meta
            name="description"
            content="Discover our premium range of cosmetics designed to enhance your natural beauty. Shop high-quality makeup products, skincare essentials, and beauty tools that cater to every skin type and style. Enjoy fast shipping and exclusive offers!"
          />
          <meta
            name="keywords"
            content="cosmetics, makeup, skincare, beauty products, high-quality makeup, skincare essentials, natural beauty, beauty tools, online cosmetics shop, cruelty-free cosmetics"
          />
        </Helmet>
        <div className="scroll-container">
          <div className="logo">Glam Cosmetics</div>
          <div className="grid">
            <a href="https://amzn.to/4eI8Pbr" target="_blank">
              <div className="card">
                <img src={syrum} alt="Face Serum for Women & Men" />
                <h1>Face Serum for Women & Men</h1>
              </div>
            </a>
            <a href="https://amzn.to/4eCo1rf" target="_blank">
              <div className="card">
                <img src={scrub} alt="Everyuth Naturals Walnut Apricot Scrub" />
                <h1>Everyuth Naturals Walnut Apricot Scrub</h1>
              </div>
            </a>
            <a href="https://amzn.to/4eLmsXC" target="_blank">
              <div className="card">
                <img
                  src={himalayaSyrum}
                  alt="Himalaya Dark Spot Clearing Turmeric Face Serum"
                />
                <h1>Himalaya Dark Spot Clearing Turmeric Face Serum</h1>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlamCosmetics;
